export enum SocketEventTypeEnum {
  "error" = "error",

  // messages
  "addMessage" = "addMessage",
  "messageAdded" = "messageAdded",
  "response_SendMessage_error" = "response_SendMessage_error",

  // rooms
  "rooms" = "rooms",
  "joinRoom" = "joinRoom",
  "newRoom" = "newRoom",
  "removeRoom" = "removeRoom",
  "roomChanged" = "roomChanged",
  "userRoomChanged" = "userRoomChanged",
  "memberChanged" = "memberChanged",
  "changeUserRoomStatuses" = "changeUserRoomStatuses",

  // key exchange
  "request_ComputeRoomKey" = "request_ComputeRoomKey",
  "response_ComputeRoomKey" = "response_ComputeRoomKey",
  "request_ComputeRoomKey_Done" = "request_ComputeRoomKey_Done",
  "response_ComputeRoomKey_Done" = "response_ComputeRoomKey_Done",

  // secret chat
  "answer" = "answer",
  "offer" = "offer",
  "candidate" = "candidate",

  // stream
  "streamStarted" = "streamStarted",
  "streamEnded" = "streamEnded",

  // room call
  "roomCallStarted" = "roomCallStarted",
  "roomCallEnded" = "roomCallEnded",
  "roomCallSendLeaveRequest" = "roomCallSendLeaveRequest",
  "roomCallSendJoinRequest" = "roomCallSendJoinRequest",
  "roomCallReceiveLeaveRequest" = "roomCallReceiveLeaveRequest",
  "roomCallReceiveJoinRequest" = "roomCallReceiveJoinRequest",
  "roomCallDisconnectedAll" = "roomCallDisconnectedAll",

  // connected_device
  "request_RoomMember_Online" = "request_RoomMember_Online",
  "response_RoomMember_Online" = "response_RoomMember_Online",
  "response_RoomMember_Offline" = "response_RoomMember_Offline",

  // restart room key exchange
  "response_RestartKeyExchange" = "response_RestartKeyExchange",
}
