import { Stack } from "@mui/material";
import { useMemo } from "react";
import { Navigate, Outlet } from "react-router-dom";
import {
  ChatContextProvider,
  ConnectionContextProvider,
  KeyExchangingProvider,
  MessageContextProvider,
  useAuthContext,
} from "../../contexts";
import RoomCallContextProvider from "../../contexts/RoomCallContext";
import SideBar from "./SideBar";

const DashboardLayout = () => {
  const { userData } = useAuthContext();
  const isAuthenticated = useMemo(() => !!userData?.id, [userData?.id]);
  if (!isAuthenticated) {
    return <Navigate to="/auth/login" />;
  }

  return (
    <ChatContextProvider>
      <MessageContextProvider>
        <KeyExchangingProvider>
          <RoomCallContextProvider>
            <ConnectionContextProvider>
              <Stack direction="row">
                {/* SideBar */}
                <SideBar />
                <Outlet />
              </Stack>
            </ConnectionContextProvider>
          </RoomCallContextProvider>
        </KeyExchangingProvider>
      </MessageContextProvider>
    </ChatContextProvider>
  );
};

export default DashboardLayout;
