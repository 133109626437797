export const compareArrays = (a: any[], b: any[]) => {
  return JSON.stringify(a) === JSON.stringify(b);
};
export const mergeArrays = <T>(fieldToCompare: string, ...args: T[][]) => {
  const ids = {};
  const result: T[] = [];
  args.forEach((array) => {
    array.forEach((item) => {
      if (ids[item[fieldToCompare]]) {
        return;
      } else {
        ids[item[fieldToCompare]] = true;
        result.push(item);
      }
    });
  });

  return result;
};
