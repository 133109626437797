import {
  RoomConnectionType,
  RoomDisplayType,
  RoomEncryptType,
  RoomEncryptTypeEnum,
} from "../services";

export const ROOM_DISPLAY_TYPE: {
  value: RoomDisplayType;
  title: string;
  description: string;
}[] = [
  {
    value: "private",
    title: "Private",
    description: "Only people with invitation could join",
  },
  {
    value: "normal",
    title: "Normal",
    description:
      "Only people with invitation or acceptance from owner could join",
  },
  { value: "public", title: "Public", description: "Any one can join" },
];

export const ROOM_CONNECTION_TYPE: {
  value: RoomConnectionType;
  title: string;
  description: string;
}[] = [
  {
    value: "server",
    title: "Server",
    description: "Message distributes through servers",
  },
  {
    value: "p2p",
    title: "P2P",
    description: "Message distributes through peer to peer technology",
  },
];

export const ROOM_ENCRYPT_TYPE: {
  value: RoomEncryptType;
  title: string;
  description: string;
}[] = [
  {
    value: RoomEncryptTypeEnum.none,
    title: "None",
    description: "No encryption",
  },
  {
    value: RoomEncryptTypeEnum.aes_256_cbc,
    title: "Basic Encryption",
    description: "Basic Encryption using AES 256 CBC",
  },
  {
    value: RoomEncryptTypeEnum.key_exchange,
    title: "End-to-end encryption",
    description:
      "End to end encryption using key exchange between member devices",
  },
];

export const STATUS = {
  JOINED: "joined",
  REQUESTED: "requested",
  REJECTED: "rejected",
  REMOVED: "removed",
  BANNED: "banned",
  LEFT: "left",
};

export const DISPLAY_TYPE = {
  PRIVATE: "private",
  NORMAL: "normal",
  PUBLIC: "public",
} as const;

export const ROOM_MESSAGE_TYPE = {
  NORMAL: "normal",
  NON_MANY: "non_many",
} as const;

export const CONNECTION_TYPE = {
  SERVER: "server",
  P2P: "p2p",
} as const;

export const ENCRYPT_TYPE = {
  NONE: "none",
  AES_256_CBC: "aes_256_cbc",
  KEY_EXCHANGE: "key_exchange",
} as const;

export const MAX_WIDTH_SIDEBAR = 400;

export const SIDEBAR_TYPE = {
  CONTACT: "CONTACT",
  STARRED: "STARRED",
  SHARED: "SHARED",
};

export const PATH_INDEX = [0, 2, 3];
export const MENU_PATH_INDEX = [0, 1, 2];

export const TYPE = {
  MSG: "msg",
  DIVIDER: "divider",
};
export const SUBTYPE = {
  TEXT: "text",
  IMG: "img",
  DOC: "doc",
  LINK: "link",
  REPLY: "reply",
} as const;
export const MESSAGE_TYPE = {
  FILE: "file",
  HTML: "html",
  TEXT: "text",
};

export const TOOLBAR_OPTIONS = {
  INLINE: "inline",
  BLOCKTYPE: "blockType",
  LIST: "list",
};

export const TOOLBAR_INLINE_OPTIONS = {
  BOLD: "bold",
  ITALIC: "italic",
  UNDERLINE: "underline",
};

export const BLOCKTYPE_OPTIONS = {
  NORMAL: "Normal",
  H1: "H1",
  H2: "H2",
  H3: "H3",
  H4: "H4",
  H5: "H5",
  H6: "H6",
  BLOCKQUOTE: "Blockquote",
};

export const LIST_OPTIONS = {
  UNORDERED: "unordered",
  ORDERED: "ordered",
};

export const KEY_DOWN = {
  ARROWUP: "ArrowUp",
  ARROWDOWN: "ArrowDown",
  ENTER: "Enter",
};

export const TAG_REGEX = /@(\w+)/g;
export const REGEX = /@(\w*)$/;

export const SETTLED_STATUS = {
  FULFILLED: "fulfilled",
  REJECTED: "rejected",
} as const;

export const FAB_KEY = {
  PHOTO_VIDEO: "photo-video",
  IMAGE: "image",
  DOCUMENT: "document",
  STICKER: "sticker",
  CONTACT: "contact",
};
