import {
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useCallback, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Logo from "../../assets/Images/jchat-beta.svg";
import IconSupport from "../../assets/Images/support-icon.png";
import AntSwitch from "../../components/AntSwitch";
import { MENU_PATH_INDEX, PATH_INDEX } from "../../constant/room";
import {
  useAuthContext,
  useChatContext,
  useMessageContext,
} from "../../contexts";
import { Nav_Buttons, Profile_Menu } from "../../data";
import { getUserAvatar } from "../../helpers/chat-user-helper";
import useSettings from "../../hooks/useSettings";
import { setSearchValue } from "../../redux/slices/chat";
import { useAppDispatch } from "../../redux/store";

const getPath = (index) => {
  switch (index) {
    case PATH_INDEX[0]:
      return "/app";

    case PATH_INDEX[1]:
      return "/call";

    case PATH_INDEX[2]:
      return "/settings";

    default:
      break;
  }
};

const SideBar = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { userData } = useAuthContext();
  const { roomKey } = useParams();
  const { setSelectedRoom } = useChatContext();
  const { setSelectedThread } = useMessageContext();
  const theme = useTheme();
  const navigate = useNavigate();
  // state for selected button
  const [selected, setSelected] = useState(0); // by default 0 index button is selected
  //switch themes
  const { onToggleMode } = useSettings();
  const dispatch = useAppDispatch();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { logout } = useAuthContext();

  const getMenuPath = useCallback(
    (index) => {
      switch (index) {
        case MENU_PATH_INDEX[0]:
          return "/profile";

        // case MENU_PATH_INDEX[1]:
        //   return "/settings";

        case MENU_PATH_INDEX[1]:
          logout();
          return "/auth/login";

        default:
          break;
      }
    },
    [logout],
  );

  return (
    <Box
      p={2}
      sx={{
        backgroundColor: theme.palette.background.paper,
        boxShadow: `1px 0px 0px ${theme.palette.grey[300]}`,
        height: "100vh",
        width: 100,
        display: "flex",
      }}
    >
      <Stack
        direction="column"
        alignItems={"center"}
        justifyContent="space-between"
        sx={{ width: "100%", height: "100%" }}
        spacing={3}
      >
        <Stack alignItems={"center"} spacing={4}>
          <Link to={!roomKey && "/app"}>
            <Box
              sx={{
                height: 64,
                width: 64,
                borderRadius: 1.5,
                overflow: "hidden",
              }}
            >
              <img src={Logo} alt={"Logo icon"} />
            </Box>
          </Link>
          <Stack
            sx={{ width: "max-content" }}
            direction="column"
            alignItems="center"
            spacing={3}
          >
            {Nav_Buttons.map((el) =>
              el.index === selected ? (
                <Box
                  key={""}
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: 1.5,
                  }}
                >
                  <IconButton
                    sx={{
                      width: "max-content",
                      color: theme.palette.background.default,
                    }}
                    key={el.index}
                  >
                    {el.icon}
                  </IconButton>
                </Box>
              ) : (
                <IconButton
                  onClick={() => {
                    setSelected(el.index);
                    navigate(!roomKey && getPath(el.index));
                  }}
                  sx={{
                    width: "max-content",
                    color:
                      theme.palette.mode === "light"
                        ? theme.palette.text.secondary
                        : theme.palette.text.primary,
                  }}
                  key={el.index}
                >
                  {el.icon}
                </IconButton>
              ),
            )}

            {/* Setting */}

            {/* <Divider sx={{ width: "48px" }} /> */}
            {/* {selected === 3 ? (
              <Box
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: 1.5,
                }}
              >
                <IconButton
                  sx={{
                    width: "max-content",
                    color: theme.palette.background.default,
                  }}
                >
                  <Gear />
                </IconButton>
              </Box>
            ) : (
              <IconButton
                onClick={() => {
                  setSelected(3);
                  navigate(getPath(3));
                }}
                sx={{
                  width: "max-content",
                  color:
                    theme.palette.mode === "light"
                      ? theme.palette.text.secondary
                      : theme.palette.text.primary,
                }}
              >
                <Gear />
              </IconButton>
            )} */}
          </Stack>
        </Stack>

        <Stack spacing={2}>
          <AntSwitch
            onChange={() => {
              onToggleMode();
            }}
            defaultChecked
          />
          <Stack
            sx={{
              position: "relative",
            }}
          >
            <Link to={"/app/JChat-Support-eqxg7k"}>
              <Tooltip title={"Support"} placement="right">
                <Avatar
                  id="basic-button"
                  sx={{ cursor: "pointer" }}
                  src={IconSupport}
                />
              </Tooltip>
            </Link>
          </Stack>
          <Stack
            sx={{
              position: "relative",
            }}
          >
            <Avatar
              id="basic-button"
              sx={{ cursor: "pointer" }}
              src={getUserAvatar(userData)}
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            />
          </Stack>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "bottom", horizontal: "left" }}
          >
            <Stack spacing={1} px={1}>
              {Profile_Menu.map((el, idx) => (
                <MenuItem
                  key={idx}
                  onClick={() => {
                    handleClick(el);
                    setSelected(
                      idx === MENU_PATH_INDEX[1] ? PATH_INDEX[2] : null,
                    );
                    dispatch(setSearchValue(""));
                    navigate(getMenuPath(idx));
                  }}
                >
                  <Stack
                    sx={{ width: 80 }}
                    direction="row"
                    alignItems={"center"}
                    justifyContent="space-between"
                  >
                    <span>{el.title}</span>
                    {el.icon}
                  </Stack>
                </MenuItem>
              ))}
            </Stack>
          </Menu>
        </Stack>
      </Stack>
    </Box>
  );
};

export default SideBar;
