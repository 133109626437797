import DOMPurify from "dompurify";
import {
  CustomerUserInfo,
  UserProfileInfo,
} from "../components/create-room-dialog/types";
import { TAG_REGEX } from "../constant/room";
import { ChatUserInfo, UserInfo } from "../contexts";
import { Image, Room, UserRoom, UserRoomStatus } from "../services";

const DEFAULT_AVATAR = "/assets/Images/default_image.png";

const ChatUserHelper = {
  getUserDisplayString: (user: ChatUserInfo | UserProfileInfo | UserInfo) => {
    return (
      (user as ChatUserInfo)?.username ??
      (user as ChatUserInfo)?.email ??
      (user as UserProfileInfo)?.phone_number ??
      (user as UserInfo)?.user_profile_info?.email ??
      ""
    )
      .split("|")
      .pop();
  },
};

export const getUserAvatar = (data: UserInfo | Image) => {
  return (
    (data as UserInfo)?.user_profile_info?.avatar ||
    (data as Image)?.senderInfo?.User.avatar ||
    DEFAULT_AVATAR
  );
};

export const getUserRoomAvatar = (data: UserRoom | UserRoomStatus) => {
  return (
    (data as UserRoomStatus)?.UserRoom?.User.avatar ||
    (data as UserRoom)?.User.avatar ||
    DEFAULT_AVATAR
  );
};

export const getRoomAvatar = (data: Room) => {
  return data.avatar || DEFAULT_AVATAR;
};

export const formatMessageWithLinks = (message) => {
  const urlRegex = /(https?:\/\/[^\s]+)/;

  return message.replace(urlRegex, (url) => {
    const href = url.startsWith("http") ? url : `https://${url}`;
    return `<a href="${href}" target="_blank">${url}</a>`;
  });
};

export const isLink = (text) => {
  const urlRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+)/;
  return urlRegex.test(text);
};

export const createMarkup = (html: string | Node) => {
  return {
    __html: DOMPurify.sanitize(html),
  };
};

export const extractContentFromHTML = (html: string) => {
  const div = document.createElement("div");
  div.innerHTML = html;

  const textContent = div.textContent || div.innerText;

  return textContent?.trim().length > 0 ? textContent.trim() : null;
};

export const findUsernameTagged = (message) => {
  const tags = [...(message ?? "").matchAll(TAG_REGEX)];
  return (tags ?? []).map((tag) => tag[1]);
};

export default ChatUserHelper;
