import { UserRoom } from "../services";
import { getDisplayKeyFromPrefixString } from "./prefix-helper";

export const getUserRoomDisplayName = (userRoom: UserRoom) => {
  return (
    userRoom?.nickname ||
    getDisplayKeyFromPrefixString(
      userRoom.User.username ?? userRoom.User.email,
    ) ||
    "Unknown"
  );
};
