export enum LocalStorageKey {
  "KeyPair" = "KeyPair",
  "RoomEncryptKey" = "RoomEncryptKey",
  "RoomMember" = "RoomMember",
  "UserData" = "UserData",
  "PasswordIsSet" = "PasswordIsSet",
  "SideBarWidth" = "SideBarWidth",
  "TextInputMode" = "TextInputMode",
  "width" = "width",
  "Checked" = "Checked",
}
