import { sha256 } from "js-sha256";
import {
  createContext,
  Dispatch,
  MutableRefObject,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  GET_ROOM_NEED_TO_KEY_EXCHANGE_INTERVAL,
  PROCESS_ROOM_BATCH_SIZE,
  PROCESS_ROOM_NEED_TO_KEY_EXCHANGE_BATCH_SIZE,
  PROCESS_ROOM_NEED_TO_KEY_EXCHANGE_INTERVAL,
} from "../constant/key-exchange";
import { Room } from "../services";
import {
  handleComputeKeyExchange,
  handleGetOneRoomNeedExchangeKey,
  handleGetRoomKeyExchangeStatus,
  handleGetRoomOnlineMembers,
  handleGetRoomOnlineMemberStatus,
  handleGetRoomsNeedExchangeKey,
  handleStartKeyExchange,
  handleUpdateRoomKeyExchange,
  handleUpdateUserRoomStatusKeyExchange,
} from "../services/api";
import {
  RoomKeyExchangeClientStatus,
  StartKeyExchangeRequest,
  UserKeyPair,
} from "../types/key-exchange";
import { PaginationLimitOffset } from "../types/room";
import {
  getLocalStorageRoomEncryptKeys,
  getUserKeyPair,
  setLocalStorageRoomEncryptKey,
} from "../utils/local-storage";
import { useAuthContext } from "./AuthContext";
import { toast } from "react-toastify";

interface KeyExchangingProcessRoom {
  room: Room;
  isProcessing: boolean;
  id: string;
}

const KeyExchangingContext = createContext<{
  roomKeyExchangeStatus: Record<
    string,
    {
      status: RoomKeyExchangeClientStatus;
      room: Room;
      roomKeyExchangeId: string;
    }
  >;
  roomEncryptKey: Record<string, string>;
  userKeyPair: UserKeyPair | null;
  handleDoneGetShareKeyRoom: (
    curve_params: Record<string, string>,
    userPublicKeys: Record<string, string>,
    roomKeyExchangeId: string,
    roomId: string,
    isOwnerDone: boolean,
  ) => void;
  refReadyForPreprocessing: MutableRefObject<
    Record<string, KeyExchangingProcessRoom>
  >;
  setRoomKeyExchangeStatus: Dispatch<
    SetStateAction<
      Record<
        string,
        {
          status: RoomKeyExchangeClientStatus;
          room: Room;
          roomKeyExchangeId: string;
        }
      >
    >
  >;
  handleRestartKeyExchangingProcess: (
    roomKeyExchangeId: string,
  ) => Promise<void>;
}>({
  roomKeyExchangeStatus: {},
  roomEncryptKey: {},
  userKeyPair: null,
  handleDoneGetShareKeyRoom: () => {},
  refReadyForPreprocessing: null,
  setRoomKeyExchangeStatus: () => {},
  handleRestartKeyExchangingProcess: async () => {},
});

export const KeyExchangingProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [roomKeyExchangeStatus, setRoomKeyExchangeStatus] = useState<
    Record<
      string,
      {
        status: RoomKeyExchangeClientStatus;
        room: Room;
        roomKeyExchangeId: string;
      }
    >
  >({});

  const { userData } = useAuthContext();

  const [userKeyPair, setUserKeyPair] = useState<UserKeyPair>();
  const [roomEncryptKey, setRoomEncryptKey] = useState<Record<string, string>>(
    {},
  );
  const roomEncryptKeyRef = useRef<Record<string, string>>(roomEncryptKey);
  roomEncryptKeyRef.current = roomEncryptKey;
  const userKeyPairRef = useRef<UserKeyPair>(userKeyPair);
  userKeyPairRef.current = userKeyPair;

  const refRoomKeyExchangeStatus = useRef<
    Record<
      string,
      {
        status: RoomKeyExchangeClientStatus;
        room: Room;
        roomKeyExchangeId: string;
      }
    >
  >({});
  refRoomKeyExchangeStatus.current = roomKeyExchangeStatus;

  const handleSetKeyExchangeStatus = useCallback(
    (
      roomId: string,
      status: RoomKeyExchangeClientStatus,
      room: Room,
      roomKeyExchangeId: string,
    ) => {
      setRoomKeyExchangeStatus((prev) => ({
        ...prev,
        [roomId]: { status, room, roomKeyExchangeId },
      }));
    },
    [],
  );

  const refParamsGetRoomsNeedToExchangeKey = useRef<PaginationLimitOffset>({
    limit: PROCESS_ROOM_NEED_TO_KEY_EXCHANGE_BATCH_SIZE,
    offset: 0,
  });

  const refIntervalProcessing = useRef<NodeJS.Timeout>();
  const refIntervalGetRoomsNeedToExchangeKey = useRef<NodeJS.Timeout>();

  const refWaitForOwnerStart = useRef<Record<string, KeyExchangingProcessRoom>>(
    {},
  );
  const refReadyStartComputing = useRef<
    Record<string, KeyExchangingProcessRoom>
  >({});
  const refWaitForOnlineMembers = useRef<
    Record<string, KeyExchangingProcessRoom>
  >({});
  const refReadyForPreprocessing = useRef<
    Record<string, KeyExchangingProcessRoom>
  >({});
  useEffect(() => {
    if (userData?.id) {
      refIntervalGetRoomsNeedToExchangeKey.current = setInterval(async () => {
        const result = await handleGetRoomsNeedExchangeKey(
          refParamsGetRoomsNeedToExchangeKey.current,
        );
        if (result) {
          setRoomKeyExchangeStatus((prev) => {
            const newRoomKeyExchangeStatus = result.reduce(
              (pre, curr) => ({
                ...pre,
                [curr.id]: {
                  status: "checking_key_exchange_status",
                  room: curr,
                },
              }),
              {},
            );

            return {
              ...prev,
              ...newRoomKeyExchangeStatus,
            };
          });
          handleCheckRoomNeedToExchangeKey(result);

          if (result.length === PROCESS_ROOM_NEED_TO_KEY_EXCHANGE_BATCH_SIZE) {
            refParamsGetRoomsNeedToExchangeKey.current.offset += result.length;
          } else {
            clearInterval(refIntervalGetRoomsNeedToExchangeKey.current);
          }
        }
      }, GET_ROOM_NEED_TO_KEY_EXCHANGE_INTERVAL);
    }

    return () => clearInterval(refIntervalGetRoomsNeedToExchangeKey.current);
  }, [userData?.id]);

  const handleSetRoomEncryptKey = useCallback((roomId: string, key: string) => {
    setLocalStorageRoomEncryptKey(roomId, key);
    setRoomEncryptKey((prev) => ({
      ...prev,
      [roomId]: key,
    }));
  }, []);

  const handleDoneGetShareKeyRoom = useCallback(
    async (
      curve_params: Record<string, string>,
      userPublicKeys: Record<string, string>,
      roomKeyExchangeId: string,
      roomId: string,
      isOwnerDone: boolean = false,
    ) => {
      const { Gx, Gy } = curve_params;

      const key = sha256(JSON.stringify({ Gx, Gy }));

      handleSetRoomEncryptKey(roomId, key);

      // update room key exchange
      if (isOwnerDone) {
        await handleUpdateRoomKeyExchange({
          status: "isOwnerDone",
          members: userPublicKeys,
          roomKeyExchangeId,
          publicKey: userKeyPairRef.current?.public_key,
        });
      }

      await handleUpdateUserRoomStatusKeyExchange({
        publicKey: userKeyPairRef.current?.public_key,
        status: "done",
        members: userPublicKeys,
        roomKeyExchangeId,
      });

      handleSetKeyExchangeStatus(
        roomId,
        "done",
        refRoomKeyExchangeStatus.current[roomId].room,
        roomKeyExchangeId,
      );

      delete refReadyStartComputing.current[roomId];
    },
    [],
  );

  const handleCheckRoomNeedToExchangeKey = useCallback((room: Room[]) => {
    room.forEach((item) => {
      if (!item?.currentUserRoomStatus) {
        return;
      }
      const currentUserRoomStatus = item?.currentUserRoomStatus;
      const currentUserStatus =
        item.RoomKeyExchange[0].UserRoomStatusKeyExchange.find(
          (userStatus) =>
            userStatus.userRoomStatusId === currentUserRoomStatus.id,
        );
      const roomKeyExchange = item.RoomKeyExchange[0];
      if (currentUserStatus?.status === "done") {
        const encryptKey = roomEncryptKeyRef.current[item.id];

        handleSetKeyExchangeStatus(
          item.id,
          encryptKey ? "done" : "missing_encryption_key",
          item,
          roomKeyExchange?.id,
        );
      } else {
        refReadyForPreprocessing.current[item.id] = {
          room: item,
          isProcessing: false,
          id: item.id,
        };
      }
    });
  }, []);

  const handleKeyExchange2Members = useCallback(async (room: Room) => {
    const onlineUsers = (await handleGetRoomOnlineMembers(room.id)) ?? [];
    const otherPeer = onlineUsers.filter(
      (item) => item.userId !== userData?.id,
    );
    const result = await handleComputeKeyExchange({
      private_key: userKeyPairRef.current?.private_key,
      public_key: otherPeer[0].publicKey,
    });

    const isOwner = room.users.find(
      (item) => item.userId === userData?.id,
    )?.isCreator;

    if (result.data) {
      handleDoneGetShareKeyRoom(
        result.data,
        onlineUsers.reduce((pre, curr) => {
          return { ...pre, [curr.userId]: curr.publicKey };
        }, {}),
        room.RoomKeyExchange[0]?.id,
        room.id,
        isOwner,
      );
      delete refReadyStartComputing.current[room.id];
    } else {
      refReadyStartComputing.current[room.id].isProcessing = false;
    }
  }, []);

  const handleKeyExchangeRoomMembers = useCallback((rooms: Room[]) => {
    rooms.forEach(async (room) => {
      refReadyStartComputing.current[room.id].isProcessing = true;

      handleSetKeyExchangeStatus(
        room.id,
        "computing",
        room,
        room.RoomKeyExchange[0]?.id,
      );

      // console.log("handleKeyExchangeRoomMembers", room.id);

      if (room.users.length > 1) {
        if (room.users.length === 2) {
          handleKeyExchange2Members(room);
        } else {
          const onlineUsers = (await handleGetRoomOnlineMembers(room.id)) ?? [];
          if (
            !room.users.every((item) =>
              onlineUsers.find((user) => user.userId === item.userId),
            )
          ) {
            refReadyStartComputing.current[room.id].isProcessing = false;
          }
          const ownerUser = room.users.find((item) => item.isCreator);
          let otherPeers = onlineUsers
            .filter((item) => item.userId !== userData?.id)
            .map((item) => item.publicKey);

          let userPublicKeyList = onlineUsers.reduce((pre, curr) => {
            return { ...pre, [curr.userId]: curr.publicKey };
          }, {});

          if (ownerUser?.userId !== userData?.id) {
            try {
              const resultGetOne = await handleGetOneRoomNeedExchangeKey(
                room.id,
              );
              if (resultGetOne && resultGetOne.RoomKeyExchange?.length) {
                const newOtherMembers = {
                  ...resultGetOne.RoomKeyExchange[0].members,
                };

                if (userData?.id) {
                  delete newOtherMembers[userData?.id];
                }

                otherPeers = Object.values(newOtherMembers);

                userPublicKeyList = resultGetOne.RoomKeyExchange[0].members;
              }
            } catch (error) {
              console.error("Cannot get one room need to exchange key", error);
            }
          }

          const currentPeer = onlineUsers.find(
            (item) => item.userId === userData?.id,
          );

          if (!currentPeer) {
            console.error("Current user not found");
            return;
          }

          const startKeyExchangeRequest: StartKeyExchangeRequest = {
            nextMemberPublicKeys: otherPeers,
            roomId: room.id,
            userPublicKeyList,
            roomKeyExchangeId: room.RoomKeyExchange[0]?.id,
            isOwner: ownerUser?.userId === userData?.id,
            ownerPublicKey: onlineUsers.find(
              (item) => item.userId === ownerUser?.userId,
            )?.publicKey,
          };
          await handleStartKeyExchange(startKeyExchangeRequest);
        }
      }
    });
  }, []);

  const handleProcessRoomWaitForOwnerStart = useCallback((rooms: Room[]) => {
    rooms.forEach(async (room) => {
      handleSetKeyExchangeStatus(
        room.id,
        "wait_for_owner_start",
        room,
        room.RoomKeyExchange[0]?.id,
      );
      refWaitForOwnerStart.current[room.id].isProcessing = true;

      // console.log("handle process room wait for owner start", room.id);

      const roomKeyExchangeStatus = await handleGetRoomKeyExchangeStatus(
        room.RoomKeyExchange[0]?.id,
      );

      if (roomKeyExchangeStatus === "isOwnerDone") {
        refWaitForOnlineMembers.current[room.id] = {
          isProcessing: false,
          room,
          id: room.id,
        };

        delete refWaitForOwnerStart.current[room.id];
      } else {
        refWaitForOwnerStart.current[room.id].isProcessing = false;
      }
    });
  }, []);

  const handleProcessWaitForOnlineMembers = useCallback((rooms: Room[]) => {
    rooms.forEach(async (room) => {
      handleSetKeyExchangeStatus(
        room.id,
        "wait_for_online_members",
        room,
        room.RoomKeyExchange[0]?.id,
      );

      refWaitForOnlineMembers.current[room.id].isProcessing = true;

      try {
        const onlineUsers = await handleGetRoomOnlineMemberStatus(room.id);
        if (
          onlineUsers &&
          room.users
            .map((item) => item.userId)
            .every((userId) => onlineUsers[userId] === "online")
        ) {
          refReadyStartComputing.current[room.id] = {
            isProcessing: false,
            room,
            id: room.id,
          };

          delete refWaitForOnlineMembers.current[room.id];
        } else {
          refWaitForOnlineMembers.current[room.id].isProcessing = false;
        }
      } catch (error) {
        console.error("Error get room online member status", error);
        // console.log("error get room online member status", error);
      }
    });
  }, []);

  const handlePreprocessingRoom = useCallback(async (rooms: Room[]) => {
    if (rooms.length === 0) return;

    const ownerRooms = rooms.filter(
      (item) => item?.currentUserRoomStatus.UserRoom.isCreator,
    );
    const notOwnerRooms = rooms.filter(
      (item) => !item?.currentUserRoomStatus.UserRoom.isCreator,
    );

    const ownerRoomsKeyExchangeStatus = await Promise.allSettled(
      ownerRooms.map(async (room) => {
        const result = await handleGetRoomKeyExchangeStatus(
          room.RoomKeyExchange[0]?.id,
        );
        return {
          room,
          result,
        };
      }),
    );

    ownerRoomsKeyExchangeStatus.forEach((item) => {
      if (item.status === "fulfilled") {
        // console.log("handlePreprocessingRoom", "ownerRooms", item.value);
        delete refReadyForPreprocessing.current[item.value.room.id];

        if (
          item.value.result === "created" ||
          item.value.result === "ongoing"
        ) {
          refWaitForOnlineMembers.current[item.value.room.id] = {
            isProcessing: false,
            room: item.value.room,
            id: item.value.room.id,
          };
        }
      }
    });

    const notOwnerRoomsKeyExchangeStatus = await Promise.allSettled(
      notOwnerRooms.map(async (room) => {
        const result = await handleGetRoomKeyExchangeStatus(
          room.RoomKeyExchange[0]?.id,
        );
        return {
          room,
          result,
        };
      }),
    );

    notOwnerRoomsKeyExchangeStatus.forEach((item) => {
      if (item.status === "fulfilled") {
        delete refReadyForPreprocessing.current[item.value.room.id];

        // console.log("handlePreprocessingRoom", "notOwnerRooms", item.value);

        switch (item.value.result) {
          case "created":
          case "ongoing":
            refWaitForOwnerStart.current[item.value.room.id] = {
              isProcessing: false,
              room: item.value.room,
              id: item.value.room.id,
            };

            break;

          case "done":
          case "isOwnerDone":
            refWaitForOnlineMembers.current[item.value.room.id] = {
              isProcessing: false,
              room: item.value.room,
              id: item.value.room.id,
            };

            break;

          default:
            break;
        }
      }
    });
  }, []);

  const handleRestartKeyExchangingProcess = useCallback(
    async (roomKeyExchangeId: string) => {
      try {
        const result = await handleUpdateRoomKeyExchange({
          status: "created",
          roomKeyExchangeId,
        });

        return result;
      } catch (error) {
        toast.error("Error restart key exchanging process");
      }
    },
    [],
  );

  useEffect(() => {
    if (userData?.id) {
      const keyPair = getUserKeyPair();
      if (keyPair && keyPair.public_key && keyPair.private_key) {
        setUserKeyPair(keyPair);
      }

      const roomEncryptKeys = getLocalStorageRoomEncryptKeys();
      if (roomEncryptKeys) {
        setRoomEncryptKey(roomEncryptKeys);
      }
      refIntervalProcessing.current = setInterval(async () => {
        if (Object.keys(refReadyForPreprocessing.current).length > 0) {
          handlePreprocessingRoom(
            Object.values(refReadyForPreprocessing.current)
              .filter((item) => !item.isProcessing)
              .slice(0, PROCESS_ROOM_BATCH_SIZE)
              .map((item) => item.room),
          );
        }
        if (Object.values(refWaitForOwnerStart.current).length > 0) {
          handleProcessRoomWaitForOwnerStart(
            Object.values(refWaitForOwnerStart.current)
              .filter((item) => !item.isProcessing)
              .slice(0, PROCESS_ROOM_BATCH_SIZE)
              .map((item) => item.room),
          );
        }

        if (Object.values(refWaitForOnlineMembers.current).length > 0) {
          handleProcessWaitForOnlineMembers(
            Object.values(refWaitForOnlineMembers.current)
              .filter((item) => !item.isProcessing)
              .slice(0, PROCESS_ROOM_BATCH_SIZE)
              .map((item) => item.room),
          );
        }

        if (Object.values(refReadyStartComputing.current).length > 0) {
          handleKeyExchangeRoomMembers(
            Object.values(refReadyStartComputing.current)
              .filter(
                (item) => !item.isProcessing && item.room.users.length > 1,
              )
              .slice(0, PROCESS_ROOM_BATCH_SIZE)
              .map((item) => item.room),
          );
        }
      }, PROCESS_ROOM_NEED_TO_KEY_EXCHANGE_INTERVAL);
    }

    return () => {
      clearInterval(refIntervalProcessing.current);
    };
  }, [userData?.id]);

  useEffect(() => {
    return () => {
      if (refIntervalProcessing.current) {
        clearInterval(refIntervalProcessing.current);
      }
      if (refIntervalGetRoomsNeedToExchangeKey.current) {
        clearInterval(refIntervalGetRoomsNeedToExchangeKey.current);
      }
      if (refIntervalProcessing.current) {
        clearInterval(refIntervalProcessing.current);
      }
    };
  }, []);

  return (
    <KeyExchangingContext.Provider
      value={{
        roomKeyExchangeStatus,
        roomEncryptKey,
        userKeyPair,
        handleDoneGetShareKeyRoom,
        refReadyForPreprocessing,
        setRoomKeyExchangeStatus,
        handleRestartKeyExchangingProcess,
      }}
    >
      {children}
    </KeyExchangingContext.Provider>
  );
};

export const useKeyExchangingContext = () => {
  return useContext(KeyExchangingContext);
};

export default KeyExchangingContext;
