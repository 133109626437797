import { HttpStatusCode } from "axios";
import {
  Dispatch,
  PropsWithChildren,
  RefObject,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { Socket, connect } from "socket.io-client";
import { v4 as uuidv4 } from "uuid";
// import LocalStorageKey from "../constants/local-storage-key";
// import {
//   Room,
//   UserMessage,
//   UserRoom,
//   useChatStore,
// } from "../libs/zustands/useChatStore";
// import { useKeysStore } from "../libs/zustands/useKeysStore";
// import { useRoomCallStore } from "../libs/zustands/useRoomCallStore";
import { ChatAxios, getRequestHeaders } from "../services/axios";
// import { RoomCall, UserRoomCallStatus } from "../types/room-call";
// import { UserStream } from "../types/streams";
import { config } from "../config";
import { LocalStorageKey, SocketEventTypeEnum } from "../enums";
import { setChatUnreadCount } from "../redux/slices/chat";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { handleGetOneRoomNeedExchangeKey } from "../services/api";
import { ComputeRoomKeyRequest } from "../types/key-exchange";
import { Room, RoomCall, UserMessage, UserRoom } from "../services";
import { mergeArrays } from "../utils/array";
import { handleDecryptMessageContent } from "../utils/encrypt";
import { getErrorMessage } from "../utils/error";
import { setLocalChatUnreadCount } from "../utils/local-storage";
import { useAuthContext } from "./AuthContext";
import { useChatContext } from "./ChatContext";
import { useKeyExchangingContext } from "./KeyExchangingContext";
import { useMessageContext } from "./MessageContext";
import { useRoomCallContext } from "./RoomCallContext";
// import { ComputeRoomKeyRequest, useKeyExchange } from "./key-exchange";
// import { useStreamContext } from "./stream";

const ConnectionContext = createContext<{
  socketRef: RefObject<Socket> | null;
  socketReceivedData: any[];
  setSocketReceivedData: Dispatch<SetStateAction<any[]>>;
  sendSocketData: (data: any) => void;
  isSocketConnected: boolean;
}>({
  socketRef: null,
  socketReceivedData: [],
  setSocketReceivedData: () => {},
  sendSocketData: () => {},
  isSocketConnected: false,
});

export const ConnectionContextProvider = ({ children }: PropsWithChildren) => {
  const { chatUnreadCount } = useAppSelector((state) => state.chat);
  const dispatch = useAppDispatch();
  const { userData } = useAuthContext();

  const [socketReceivedData, setSocketReceivedData] = useState<any[]>([]);
  const socketReceivedDataRef = useRef<typeof socketReceivedData>([]);

  const [isSocketConnected, setIsSocketConnected] = useState(false);
  const {
    userKeyPair,
    roomEncryptKey,
    handleDoneGetShareKeyRoom,
    refReadyForPreprocessing,
    setRoomKeyExchangeStatus,
  } = useKeyExchangingContext();
  const socketRef = useRef<Socket | null>(null);

  const { setRooms, rooms, selectedRoom, setSelectedRoom } = useChatContext();
  const {
    roomCallAttendees,
    setRoomCalls,
    setRoomCallAttendees,
    setIdRoomCall,
    roomCalls,
  } = useRoomCallContext();

  const { handleRemoveSendingMessage } = useMessageContext();

  const {
    loadingMessages,
    messageListByRoom,
    setMessageListByRoom,
    setSelectedThread,
    selectedThread,
  } = useMessageContext();

  // const { publicStreams, setPublicStreams, roomStreams, setRoomStreams } =
  //   useStreamContext();

  // const { roomCallAttendees, roomCalls, setRoomCalls, setRoomCallAttendees } =
  //   useRoomCallStore();

  // const publicStreamsRef = useRef<typeof publicStreams>({});
  // const roomStreamsRef = useRef<typeof roomStreams>({});

  const messageListByRoomRef = useRef<typeof messageListByRoom>({});
  const roomListRef = useRef<Room[]>([]);

  const userKeyPairRef = useRef<typeof userKeyPair>({
    private_key: "",
    public_key: "",
  });
  const selectedRoomRef = useRef<typeof selectedRoom>();
  const selectedThreadRef = useRef<typeof selectedThread>();
  const roomEncryptKeyRef = useRef<typeof roomEncryptKey>({});

  const roomCallsRef = useRef<typeof roomCalls>({});
  const roomCallAttendeesRef = useRef<typeof roomCallAttendees>({});

  const loadingMessageRef = useRef<typeof loadingMessages>({});

  const chatUnreadCountRef = useRef<typeof chatUnreadCount>({});

  // useEffect(() => {
  //   publicStreamsRef.current = publicStreams;
  // }, [publicStreams]);

  // useEffect(() => {
  //   roomStreamsRef.current = roomStreams;
  // }, [roomStreams]);

  userKeyPairRef.current = userKeyPair;

  roomEncryptKeyRef.current = roomEncryptKey;

  useEffect(() => {
    selectedRoomRef.current = selectedRoom;
  }, [selectedRoom]);

  useEffect(() => {
    selectedThreadRef.current = selectedThread;
  }, [selectedThread]);

  useEffect(() => {
    messageListByRoomRef.current = messageListByRoom;
  }, [messageListByRoom]);

  useEffect(() => {
    roomListRef.current = rooms ?? [];
  }, [rooms]);

  useEffect(() => {
    chatUnreadCountRef.current = chatUnreadCount;
  }, [chatUnreadCount]);

  // useEffect(() => {
  //   roomCallsRef.current = roomCalls;
  // }, [roomCalls]);

  // useEffect(() => {
  //   roomCallAttendeesRef.current = roomCallAttendees;
  // }, [roomCallAttendees]);

  useEffect(() => {
    loadingMessageRef.current = loadingMessages;
  }, [loadingMessages]);

  useEffect(() => {
    socketReceivedDataRef.current = socketReceivedData;
  }, [socketReceivedData]);

  const navigate = useNavigate();

  const sendSocketData = ({ type, ...data }) => {
    if (socketRef.current) {
      socketRef.current.emit(type, data);
    }
  };

  const addDataToSocketReceivedData = (message) => {
    if (message) {
      const newData = [
        ...socketReceivedDataRef.current,
        { ...message, id: uuidv4() },
      ];
      setSocketReceivedData(newData);
    }
  };

  const [requestHeaders, setRequestHeaders] = useState(getRequestHeaders());

  useEffect(() => {
    const handleSocket = async () => {
      if (!userData) {
        socketRef.current = null;
      }
      const publicKey = JSON.parse(
        localStorage.getItem(LocalStorageKey.KeyPair) ?? "{}",
      ).public_key;
      if (!socketRef.current && userData && publicKey) {
        socketRef.current = connect(config.WS_API_BASE_URL, {
          extraHeaders: {
            ...getRequestHeaders(),
            "x-access-token": config.ACCESS_TOKEN,
          },
          auth: {
            "x-public-key": publicKey,
          },
          path: config.WS_API_BASE_PATH,
        });

        socketRef.current.on("connect", function () {
          if (socketRef.current?.connected) {
            setIsSocketConnected(true);
          }
        });

        socketRef.current.on("disconnect", function () {
          if (!socketRef.current?.connected) {
            setIsSocketConnected(false);
          }
        });

        socketRef.current.on(SocketEventTypeEnum.error, (message) => {
          if (message.status === HttpStatusCode.Unauthorized) {
            setRequestHeaders(getRequestHeaders());
          }
        });

        socketRef.current.on(
          SocketEventTypeEnum.removeRoom,
          (roomId: string) => {
            setRooms(roomListRef.current.filter((room) => room.id !== roomId));
            // const roomShareKey = { ...roomEncryptKeyRef.current };
            // delete roomShareKey[roomId];
            // setRoomEncryptKeys(roomShareKey);
            // localStorage.setItem(
            //   LocalStorageKey.RoomEncryptKey,
            //   JSON.stringify(roomShareKey)
            // );

            const roomMembers = JSON.parse(
              localStorage.getItem(LocalStorageKey.RoomMember) ?? "{}",
            );
            delete roomMembers[roomId];
            localStorage.setItem(
              LocalStorageKey.RoomMember,
              JSON.stringify(roomMembers),
            );

            if (selectedRoom?.id === roomId) {
              setSelectedRoom(undefined);
              navigate("/messages");
            }
          },
        );

        socketRef.current.on(
          SocketEventTypeEnum.memberChanged,
          (data: { roomId: string; memberList: UserRoom[] }) => {
            const { roomId, memberList } = data;

            if (
              selectedRoomRef.current &&
              selectedRoomRef.current.id === roomId
            ) {
              setSelectedRoom({
                ...selectedRoomRef.current,
                users: memberList,
              });
            }
            setRooms(
              roomListRef.current.map((room) =>
                room.id === roomId ? { ...room, users: memberList } : room,
              ),
            );
          },
        );

        // socketRef.current.on(
        //   SocketEventTypeEnum.requestPublicKey,
        //   ({ requestorSocketId, roomId }) => {
        //     if (socketRef && socketRef.current) {
        //       socketRef.current.emit(SocketEventTypeEnum.sendPublicKey, {
        //         publicKey: JSON.parse(
        //           localStorage.getItem(LocalStorageKey.KeyPair) ?? '{}',
        //         ).public_key,
        //         senderSocketId: socketRef.current.id,
        //         receiverSocketId: requestorSocketId,
        //         senderId: userData.id,
        //         roomId,
        //       });
        //     }
        //   },
        // );

        // socketRef.current.on(
        //   SocketEventTypeEnum.streamStarted,
        //   (userStream: UserStream) => {
        //     if (userStream.userRoomId) {
        //       const newRoomStreams = {
        //         ...roomStreamsRef.current,
        //         [userStream.UserRoom.roomId]: (
        //           roomStreamsRef.current[userStream.UserRoom.roomId] ?? []
        //         ).filter((item) => item.id !== userStream.id),
        //       };
        //       if (userStream.isActive) {
        //         newRoomStreams[userStream.UserRoom.roomId] = (
        //           roomStreamsRef.current[userStream.UserRoom.roomId] ?? []
        //         ).concat(userStream);
        //       }
        //       setRoomStreams(newRoomStreams);
        //     } else {
        //       setPublicStreams({
        //         ...publicStreamsRef.current,
        //         [userStream.userId]: userStream,
        //       });
        //     }
        //   }
        // );

        // socketRef.current.on(
        //   SocketEventTypeEnum.streamEnded,
        //   (userStream: UserStream) => {
        //     if (userStream.userRoomId) {
        //       setRoomStreams({
        //         ...roomStreamsRef.current,
        //         [userStream.UserRoom.roomId]: (
        //           roomStreamsRef.current[userStream.UserRoom.roomId] ?? []
        //         ).filter((item) => item.id !== userStream.id),
        //       });
        //     } else {
        //       const newPublicStreams = { ...publicStreamsRef.current };
        //       delete newPublicStreams[userStream.userId];
        //       setPublicStreams(newPublicStreams);
        //     }
        //   }
        // );

        socketRef.current.on(
          SocketEventTypeEnum.response_ComputeRoomKey,
          async (data: ComputeRoomKeyRequest) => {
            try {
              const newNextMembemPublicKeys =
                data.nextMemberPublicKeys.slice(1);
              let result;
              if (data.curve_params) {
                // console.log("compute room key with curve params", data.roomId);
                result = await ChatAxios.postReq("/compute", {
                  private_key: userKeyPairRef.current?.private_key,
                  curve_params: data.curve_params,
                });
              } else {
                // console.log(
                //   "compute room key without curve params",
                //   data.roomId
                // );

                result = await ChatAxios.postReq("/compute", {
                  private_key: userKeyPairRef.current?.private_key,
                  public_key: data.ownerPublicKey,
                });
              }

              if (newNextMembemPublicKeys.length === 0) {
                // console.log("handle send done shared key", data.roomId);
                socketRef.current?.emit(
                  SocketEventTypeEnum.request_ComputeRoomKey_Done,
                  {
                    ...data,
                    curve_params: result.data,
                  },
                );
              } else {
                // console.log("handle send result compute", data.roomId);
                socketRef.current?.emit(
                  SocketEventTypeEnum.request_ComputeRoomKey,
                  {
                    ...data,
                    nextMemberPublicKeys: newNextMembemPublicKeys,
                    curve_params: result.data,
                  } as ComputeRoomKeyRequest,
                );
              }
            } catch (error) {
              console.error(getErrorMessage(error), error);
            }
          },
        );

        socketRef.current.on(
          SocketEventTypeEnum.response_ComputeRoomKey_Done,
          async (data: ComputeRoomKeyRequest) => {
            try {
              // console.log("compute room key with curve params", data.roomId);
              const result = await ChatAxios.postReq("/compute", {
                private_key: userKeyPairRef.current?.private_key,
                curve_params: data.curve_params,
              });
              // console.log("handle done get share key room", data.roomId);
              handleDoneGetShareKeyRoom(
                result.data,
                data.userPublicKeyList,
                data.roomKeyExchangeId,
                data.roomId,
                data.isOwner,
              );
            } catch (error) {
              console.error(getErrorMessage(error), error);
            }
          },
        );

        socketRef.current.on(
          SocketEventTypeEnum.newRoom,
          async (newRoom: Room) => {
            setRooms(mergeArrays("id", [newRoom], [...roomListRef.current]));
            if (newRoom.encryptType === "key_exchange") {
              try {
                const roomNeedExchangeKey =
                  await handleGetOneRoomNeedExchangeKey(newRoom.id);

                refReadyForPreprocessing.current[newRoom.id] = {
                  room: roomNeedExchangeKey,
                  isProcessing: false,
                  id: newRoom.id,
                };

                setRoomKeyExchangeStatus((prevState) => {
                  return {
                    ...prevState,
                    [newRoom.id]: {
                      ...prevState[newRoom.id],
                      status: "checking_key_exchange_status",
                    },
                  };
                });
              } catch (error) {
                console.error("Cannot get room need to exchange key", error);
                // console.log("Cannot get room need to exchange key", error);
              }
            }
          },
        );

        socketRef.current.on(
          SocketEventTypeEnum.userRoomChanged,
          (newUserRoom) => {
            if (
              selectedRoomRef.current &&
              selectedRoomRef.current.id === newUserRoom.id
            ) {
              setSelectedRoom({
                ...selectedRoomRef.current,
                users: selectedRoomRef.current.users.map((item) =>
                  item.userId === newUserRoom.userId ? newUserRoom : item,
                ),
              });
            }
          },
        );

        socketRef.current.on(
          SocketEventTypeEnum.messageAdded,
          async (newMessage: UserMessage) => {
            const { roomId, additionalData } = newMessage;

            const messageListByRoom = [
              ...(messageListByRoomRef.current[roomId] ?? []),
            ];

            const roomInfo = roomListRef.current.find(
              (item) => item.id === roomId,
            );

            const roomMembers = roomInfo?.users ?? [];

            const decryptedMessage = await handleDecryptMessageContent(
              newMessage.message,
              roomInfo,
            );

            if (newMessage.threadId) {
              setMessageListByRoom({
                ...messageListByRoomRef.current,
                [roomId]: messageListByRoom.map((item) =>
                  newMessage.threadId === item.id
                    ? {
                        ...item,
                        threadMessages: mergeArrays(
                          "id",
                          [{ ...newMessage, message: decryptedMessage }],
                          item.threadMessages ?? [],
                        ),
                      }
                    : item,
                ),
              });
              if (selectedThreadRef.current?.id === newMessage.threadId) {
                setSelectedThread((prev) => {
                  const update = {
                    ...prev,
                    threadMessages: mergeArrays(
                      "id",
                      [{ ...newMessage, message: decryptedMessage }],
                      prev.threadMessages || [],
                    ),
                  };
                  selectedThreadRef.current = update;
                  return update;
                });
              }
            } else {
              setMessageListByRoom((prev) => {
                const updated = {
                  ...prev,
                  [roomId]: mergeArrays(
                    "id",
                    [{ ...newMessage, message: decryptedMessage }],
                    prev[roomId] || [],
                  ),
                };
                messageListByRoomRef.current = updated;
                return updated;
              });

              setRooms(
                roomListRef.current.map((room) =>
                  room.id === roomId
                    ? {
                        ...room,
                        latestMessageObject: {
                          ...decryptedMessage,
                          senderUser: roomMembers.find(
                            (item) => item.userId === newMessage.senderId,
                          ),
                        },
                      }
                    : room,
                ),
              );
            }
            if (selectedRoomRef.current.id !== roomId) {
              const currentChatUnreadCount =
                chatUnreadCountRef.current[roomId] ?? 0;

              setLocalChatUnreadCount(roomId, currentChatUnreadCount + 1);
              dispatch(
                setChatUnreadCount({
                  roomId,
                  count: currentChatUnreadCount + 1,
                }),
              );
            }
            handleRemoveSendingMessage(additionalData.messageId);
          },
        );

        socketRef.current.on(
          SocketEventTypeEnum.response_RestartKeyExchange,
          async (data: Room) => {
            refReadyForPreprocessing.current[data.id] = {
              room: data,
              isProcessing: false,
              id: data.id,
            };

            setRoomKeyExchangeStatus((prevState) => {
              return {
                ...prevState,
                [data.id]: {
                  ...prevState[data.id],
                  status: "checking_key_exchange_status",
                },
              };
            });
          },
        );

        socketRef.current.on(
          SocketEventTypeEnum.offer,
          addDataToSocketReceivedData,
        );

        socketRef.current.on(
          SocketEventTypeEnum.answer,
          addDataToSocketReceivedData,
        );

        socketRef.current.on(
          SocketEventTypeEnum.candidate,
          addDataToSocketReceivedData,
        );

        socketRef.current.on("connect_error", (err) => {
          // console.log("connect_error", JSON.stringify(err));
        });

        // socketRef.current.on(
        //   SocketEventTypeEnum.response_RoomMember_Online,
        //   (data: { roomId: string; deviceInfo: ConnectedDevice }) => {
        //     handleAddOnlineMember(data.roomId, data.deviceInfo);
        //   },
        // );

        // socketRef.current.on(
        //   SocketEventTypeEnum.response_RoomMember_Offline,
        //   (data: { roomId: string; user }) => {
        //     handleRemoveOnlineMember(data.roomId);
        //   },
        // );

        socketRef.current.on(
          SocketEventTypeEnum.roomCallStarted,
          ({
            roomCall,
            roomId,
          }: {
            roomCall: RoomCall;
            roomId: string;
            deviCeInfo: { userId: string };
          }) => {
            setRoomCalls({
              ...roomCallsRef.current,
              [roomId]: roomCall,
            });
            setRoomCallAttendees({
              ...roomCallAttendeesRef.current,
              [roomCall.id]: [],
            });

            setIdRoomCall(roomId);
          },
        );

        // socketRef.current.on(
        //   SocketEventTypeEnum.roomCallEnded,
        //   (roomCall: RoomCall) => {
        //     const newRoomCalls = { ...roomCallsRef.current };
        //     if (newRoomCalls[roomCall.roomId]) {
        //       delete newRoomCalls[roomCall.roomId];
        //       setRoomCalls(newRoomCalls);
        //     }

        //     const newRoomCallAttendees = {
        //       ...roomCallAttendeesRef.current,
        //     };
        //     delete newRoomCallAttendees[roomCall.id];
        //     setRoomCallAttendees(newRoomCallAttendees);
        //   }
        // );

        // socketRef.current.on(
        //   SocketEventTypeEnum.roomCallReceiveJoinRequest,
        //   ({
        //     roomCall,
        //     joinUserRoomCallStatus,
        //   }: {
        //     roomCall: RoomCall;
        //     joinUserRoomCallStatus: UserRoomCallStatus;
        //   }) => {
        //     const newRoomCallAttendees = {
        //       ...roomCallAttendeesRef.current,
        //     };

        //     newRoomCallAttendees[roomCall.id] = mergeArrays(
        //       "id",
        //       [joinUserRoomCallStatus],
        //       newRoomCallAttendees[roomCall.id] ?? []
        //     );
        //     setRoomCallAttendees(newRoomCallAttendees);
        //   }
        // );

        // socketRef.current.on(
        //   SocketEventTypeEnum.roomCallReceiveLeaveRequest,
        //   ({
        //     roomCall,
        //     leaveUserRoomCallStatus,
        //   }: {
        //     roomCall: RoomCall;
        //     leaveUserRoomCallStatus: UserRoomCallStatus;
        //   }) => {
        //     const newRoomCallAttendees = {
        //       ...roomCallAttendeesRef.current,
        //     };
        //     newRoomCallAttendees[roomCall.id] = (
        //       newRoomCallAttendees[roomCall.id] ?? []
        //     ).filter((item) => item.id !== leaveUserRoomCallStatus.id);

        //     setRoomCallAttendees(newRoomCallAttendees);
        //   }
        // );
      }
    };
    handleSocket();
  }, [userData, requestHeaders]);

  useEffect(() => {
    return () => {
      if (socketRef.current) {
        socketRef.current.emit(SocketEventTypeEnum.roomCallDisconnectedAll);
        socketRef.current.disconnect();
      }
    };
  }, []);

  return (
    <ConnectionContext.Provider
      value={{
        socketRef,
        setSocketReceivedData,
        socketReceivedData,
        sendSocketData,
        isSocketConnected,
      }}
    >
      {children}
    </ConnectionContext.Provider>
  );
};

export const useConnectionContext = () => {
  return useContext(ConnectionContext);
};
