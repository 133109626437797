// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// components
import dayjs from "dayjs";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ThemeSettings from "./components/settings";
import {
  ChatContextProvider,
  ConnectionContextProvider,
  KeyExchangingProvider,
  MessageContextProvider,
} from "./contexts";
import AuthContextProvider from "./contexts/AuthContext";
import OTPContextProvider from "./contexts/OTPContext";
import { SettingContextProvider } from "./contexts/SettingContext";
import ConfirmDialogProvider from "./contexts/confirm-dialog";
import RoomCallContextProvider from "./contexts/RoomCallContext";
import AppNotification from "./components/app-notification/AppNotification";
import { LocalStorageKey } from "./enums";
import { useCallback, useState } from "react";

const relativeTime = require("dayjs/plugin/relativeTime");
const updateLocale = require("dayjs/plugin/updateLocale");
// import relativeTime from 'dayjs/plugin/relativeTime' // ES 2015

dayjs.extend(relativeTime);

dayjs.extend(updateLocale);

dayjs.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "a sec",
    m: "a min",
    mm: "%d mins",
    h: "an hr",
    hh: "%d hrs",
    d: "a day",
    dd: "%d days",
    M: "a mth",
    MM: "%d mths",
    y: "a yr",
    yy: "%d yrs",
  },
});

function App() {
  const [isPopup, setIsPopup] = useState(true);
  const isChecked =
    JSON.parse(localStorage.getItem(LocalStorageKey.Checked)) ?? null;

  const handleDismiss = useCallback(() => {
    setIsPopup(false);
  }, []);

  return (
    <>
      <ThemeProvider>
        <ThemeSettings>
          <SettingContextProvider>
            <ConfirmDialogProvider>
              <OTPContextProvider>
                <AuthContextProvider>
                  <Router />
                  {!isChecked && isPopup && (
                    <AppNotification onDismiss={handleDismiss} />
                  )}
                </AuthContextProvider>
              </OTPContextProvider>
            </ConfirmDialogProvider>
          </SettingContextProvider>
        </ThemeSettings>
      </ThemeProvider>
      <ToastContainer position="bottom-left" />
    </>
  );
}

export default App;
