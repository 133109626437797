import {
  GetListRoomMessageResponse,
  Room,
  RoomCall,
  UserRoomCallStatus,
} from "./types";

import axios, { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { CustomerUserInfo } from "../components/create-room-dialog/types";
import { STATUS } from "../constant/room";
import { ChatMessageContent } from "../types/chat-message";
import {
  ConnectedDevice,
  StartKeyExchangeRequest,
  UserOnlineStatus,
} from "../types/key-exchange";
import { PaginationLimitOffset } from "../types/room";
import { UploadResult } from "../types/upload";
import { getErrorMessage } from "../utils/error";
import { ChatAxios, ChatWSAxios, CustomerAxios, MediaAxios } from "./axios";
import { RoomEncryptType, RoomKeyExchangeStatus } from "./types";

export const handleUploadFile = async (uploadFile: File) => {
  const formData = new FormData();

  formData.append("file", uploadFile, uploadFile.name);

  formData.append(
    "request",
    JSON.stringify({
      file_name: uploadFile.name,
      slug: uuidv4(),
    }),
  );
  formData.append("option_overwrite", "false");

  const result = await MediaAxios.postReq<{ data: UploadResult }>(
    "/media/create",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );
  return result;
};

export const handleChangeStatus = async (
  userId: string,
  roomId: string,
  isAccepted: boolean,
  allowGetPreviousMessage: boolean,
) => {
  await ChatWSAxios.postReq("/api/user-room/change-request-status", {
    userId,
    roomId,
    statusType: isAccepted ? "joined" : "rejected",
    allowGetPreviousMessage,
  });
};

export const getListRequest = async (roomId: string) => {
  try {
    const result = await ChatWSAxios.getReq(
      "/api/user-room/get-requesting-list",
      {
        params: {
          roomId,
        },
      },
    );
    return result;
  } catch (error) {
    toast.error(getErrorMessage(error));
  }
};

export const handleRemoveMember = async ({
  userId,
  roomId,
}: {
  userId: string;
  roomId: string;
}) => {
  try {
    await ChatWSAxios.postReq("/api/user-room/kick-member", {
      userId,
      roomId,
    });
    toast.success("Remove member successfully!");
  } catch (error) {
    toast.error(getErrorMessage(error));
  }
};

export const handleBanMember = async (userId: string, roomId: string) => {
  try {
    await ChatWSAxios.postReq("/api/user-room/ban-member", {
      roomId,
      userId,
    });

    toast.success("Ban member successfully");
  } catch (error) {
    toast.error(getErrorMessage(error));
  }
};

export const handleChangeNickName = async (
  roomId: string,
  userId: string,
  nickname: string,
) => {
  await ChatWSAxios.postReq("/api/user-room/update", {
    roomId,
    userId,
    nickname,
  });
};

export const handleJoinRoom = async (roomId: string) => {
  const result = await ChatWSAxios.postReq("/api/user-room/join", {
    roomId,
  });
  if (result) {
    if (result.status === STATUS.JOINED) {
      toast.success("Join room successfully");
    } else {
      toast.success("Request sent");
    }
  }
};

export const handleDownload = (urlImage: string) => {
  axios({
    url: urlImage,
    method: "GET",
    responseType: "blob",
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", urlImage.split("/").pop() ?? "file");
    document.body.appendChild(link);
    link.click();
  });
};

export const handleChangeAvatar = async (avatar: string) => {
  try {
    const result = await CustomerAxios.putReq("/user/update", {
      avatar,
    });
    await ChatWSAxios.putReq("/api/users/update", {
      avatar: result?.data?.user_profile_info?.avatar,
    });

    console.log("result: ", result);
    toast.success("Edit profile successfully!");
    return result.data;
  } catch (error) {
    toast.error(getErrorMessage(error));
  }
};

export const handleChangeAvatarRoom = async (
  roomId: string,
  avatar: string,
) => {
  try {
    await ChatWSAxios.postReq(`api/rooms/update/${roomId}`, {
      avatar,
    });
    toast.success("Upload avatar successfully!!");
  } catch (error) {
    toast.error(getErrorMessage(error));
  }
};

export const handleLeaveRoom = async (roomId: string) => {
  try {
    await ChatWSAxios.postReq("/api/user-room/leave-room", {
      roomId,
    });

    toast.success("Leave room successfully!!");
  } catch (error) {
    toast.error(getErrorMessage(error));
  }
};

export const handleGetUserList = async (value: string) => {
  const result: AxiosResponse<CustomerUserInfo[]> = await CustomerAxios.getReq(
    "/user/get-list",
    {
      params: {
        page: 1,
        pageSize: 1000,
        search: value,
      },
    },
  );
  return result.data;
};

export const handleUpdateProfile = async (dataProfile) => {
  try {
    const result = await CustomerAxios.putReq("/user/update", dataProfile);
    toast.success("Update profile successfully!");
    return result.data;
  } catch (error) {
    toast.error(getErrorMessage(error));
  }
};

export const handleCreateRoom = async (roomData) => {
  await ChatWSAxios.postReq("/api/rooms/create", roomData);
};

export const handleInviteMember = async (data) => {
  await ChatWSAxios.postReq("/api/user-room/add-member", data);
};

export const handleEncryptMessageWithType = async (
  message: string,
  roomEncryptType: RoomEncryptType,
) => {
  const result = await ChatAxios.postReq("/chat/encrypt-with-type", {
    message,
    type: roomEncryptType,
  });
  return result.data.message_encrypted;
};

export const handleEncryptMessageWithKey = async (data: {
  message: string;
  key: string;
}) => {
  const result = await ChatAxios.postReq("/chat/encrypt", data);
  return result.data.message_encrypted;
};

export const handleDecryptMessageWithType = async (
  message: string,
  roomEncryptType: RoomEncryptType,
) => {
  const result = await ChatAxios.postReq<{
    data: {
      message_decrypted: string;
    };
  }>("/chat/decrypt-with-type", {
    message,
    type: roomEncryptType,
  });
  return result.data.message_decrypted;
};

export const handleDecryptMessageWithKey = async (data: {
  message: string;
  key: string;
}) => {
  const result = await ChatAxios.postReq<{
    data: {
      message_decrypted: string;
    };
  }>("/chat/decrypt", data);
  return result.data.message_decrypted;
};

export const handleGetMessageContent = async (idList: string[]) => {
  const result = await ChatAxios.postReq<{
    data: ChatMessageContent[];
  }>("/chat/get-list-message", {
    id_list: idList,
  });
  return result;
};

export const handleGetRoomOnlineMemberStatus = async (roomId: string) => {
  const result = await ChatWSAxios.getReq<Record<string, UserOnlineStatus>>(
    "/api/user-room-status/room-online-status/" + roomId,
  );
  return result;
};

export const handleGetOneRoomNeedExchangeKey = async (roomId: string) => {
  const result = await ChatWSAxios.getReq<Room>(
    "/api/room-key-exchanges/get-one-room-need-to-exchange-key/" + roomId,
  );
  return result;
};

export const handleGetRoomsNeedExchangeKey = async (
  params: PaginationLimitOffset,
) => {
  const result = await ChatWSAxios.getReq<Room[]>(
    "/api/room-key-exchanges/get-rooms-need-to-exchange-key",
    {
      params,
    },
  );
  return result;
};

export const handleStartKeyExchange = async (data: StartKeyExchangeRequest) => {
  await ChatWSAxios.postReq(
    "/api/rooms/start-key-exchange/" + data.roomKeyExchangeId,
    data,
  );
};

export const handleGetRoomOnlineMembers = async (roomId: string) => {
  const result = await ChatWSAxios.getReq<ConnectedDevice[]>(
    "/api/user-room-status/get-online-members/" + roomId,
  );
  return result;
};

export const handleComputeKeyExchange = async (data: {
  private_key: string;
  public_key?: string;
  curve_params?: Record<string, string>;
}) => {
  const result = await ChatAxios.postReq<{ data: Record<string, string> }>(
    "/compute",
    data,
  );
  return result;
};

export const handleUpdateRoomKeyExchange = async (data: {
  status: RoomKeyExchangeStatus;
  members?: Record<string, string>;
  roomKeyExchangeId: string;
  publicKey?: string;
}) => {
  await ChatWSAxios.putReq("/api/room-key-exchanges/update", data);
};

export const handleUpdateUserRoomStatusKeyExchange = async (data: {
  status: RoomKeyExchangeStatus;
  roomKeyExchangeId: string;
  members: Record<string, string>;
  publicKey: string;
}) => {
  await ChatWSAxios.putReq("/api/user-room-status-key-exchanges/update", data);
};

export const handleGetRoomKeyExchangeStatus = async (
  roomKeyExchangeId: string,
) => {
  const result = await ChatWSAxios.getReq<RoomKeyExchangeStatus>(
    "/api/room-key-exchanges/get-status/" + roomKeyExchangeId,
  );
  return result;
};

export const handleCreateRoomCall = async (
  roomId: string,
  userRoomId: string,
) => {
  const result = await ChatWSAxios.postReq<{
    newRoomCall: RoomCall;
    newUserRoomCallStatus: UserRoomCallStatus;
  }>("/api/room-call/create", {
    roomId,
    userRoomId,
  });

  return result;
};

export const handleDetailInfo = async (roomKey: string) => {
  const detailInfo = await ChatWSAxios.getReq<Room>("/api/rooms/get-one", {
    params: {
      roomKey: roomKey,
    },
  });

  return detailInfo;
};

export const handleGetRoomMessages = async (
  params: { roomId: string } & PaginationLimitOffset,
) => {
  const result = await ChatWSAxios.getReq<GetListRoomMessageResponse>(
    "/api/messages/get-list",
    {
      params,
    },
  );

  return result;
};

export const handleVerifyEmailUser = async (email: string) => {
  await CustomerAxios.getReq("/user/verify-user", {
    params: {
      username_email: email,
    },
  });
};

export const handleForgotPassword = async (email: string) => {
  await CustomerAxios.postReq("/user/forgot-password", {
    object: email,
    otp_resend: true,
  });
};

export const handleVerifyOtp = async (email: string, otp: string) => {
  await CustomerAxios.postReq(`/otp/forget-password/verify`, {
    object: email,
    otp,
    otp_type: "email",
  });
};

export const handleResetPasswort = async (
  email: string,
  otp: string,
  newPassword: string,
) => {
  await CustomerAxios.postReq("/user/reset-password", {
    object: email,
    otp: otp,
    new_password: newPassword,
  });
};
