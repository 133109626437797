import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from "react";
import { RoomCall, UserRoomCallStatus } from "../services";

const RoomCallContext = createContext<{
  roomCalls: Record<string, RoomCall>;
  setRoomCalls: (roomCalls: Record<string, RoomCall>) => void;
  idRoomCall: string;
  setIdRoomCall: React.Dispatch<React.SetStateAction<string>>;
  roomCallAttendees: Record<string, UserRoomCallStatus[]>;
  setRoomCallAttendees: (
    roomCallAttendees: Record<string, UserRoomCallStatus[]>,
  ) => void;
  currentRoomCall: RoomCall | undefined;
  setCurrentRoomCall: (currentRoomCall: RoomCall | undefined) => void;
}>({
  roomCalls: {},
  setRoomCalls: () => {},
  idRoomCall: undefined,
  setIdRoomCall: () => {},
  roomCallAttendees: {},
  setRoomCallAttendees: () => {},
  currentRoomCall: undefined,
  setCurrentRoomCall: () => {},
});

const RoomCallContextProvider = ({ children }: PropsWithChildren) => {
  const [roomCalls, setRoomCalls] = useState<Record<string, RoomCall>>({});
  const [roomCallAttendees, setRoomCallAttendees] = useState<
    Record<string, UserRoomCallStatus[]>
  >({});
  const [currentRoomCall, setCurrentRoomCall] = useState<RoomCall>();
  const [idRoomCall, setIdRoomCall] = useState<string>(undefined);

  return (
    <RoomCallContext.Provider
      value={{
        roomCalls,
        setRoomCalls,
        roomCallAttendees,
        setRoomCallAttendees,
        currentRoomCall,
        setCurrentRoomCall,
        idRoomCall,
        setIdRoomCall,
      }}
    >
      {children}
    </RoomCallContext.Provider>
  );
};

export const useRoomCallContext = () => {
  return useContext(RoomCallContext);
};

export default RoomCallContextProvider;
