import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { MESSAGE_TYPE, SUBTYPE } from "../constant/room";
import { SocketEventTypeEnum } from "../enums";
import { findUsernameTagged } from "../helpers/chat-user-helper";
import { isMessageContentValid } from "../helpers/message-helper";
import { getUserRoomDisplayName } from "../helpers/user-room";
import { useAppDispatch } from "../redux/store";
import {
  GetListRoomMessageResponse,
  Message,
  MessageMeta,
  MessageType,
  MessageTypeEnum,
  Room,
  RoomEncryptType,
  RoomEncryptTypeEnum,
  UserMessage,
  UserRoom,
} from "../services";
import {
  handleGetMessageContent,
  handleGetRoomMessages,
} from "../services/api";
import { AppMessageSubType } from "../types";
import { NotificationInfo } from "../types/chat-message";
import { PaginationLimitOffset } from "../types/room";
import { mergeArrays } from "../utils/array";
import {
  handleDecryptMessageContent,
  handleEncryptMessage,
} from "../utils/encrypt";
import { getErrorMessage } from "../utils/error";
import { getOneLocalStorageRoomEncryptKey } from "../utils/local-storage";
import { useAuthContext } from "./AuthContext";
import { useChatContext } from "./ChatContext";

const MessageContext = createContext<{
  messageListByRoom: Record<string, UserMessage[]>;
  setMessageListByRoom: (value: Record<string, UserMessage[]>) => void;
  selectedThread: UserMessage | undefined;
  setSelectedThread: (value: UserMessage) => void;
  loadingMessages: Record<string, boolean>;
  setLoadingMessages: (value: Record<string, boolean>) => void;
  totalMessagesByRoom: Record<string, number>;
  setTotalMessagesByRoom: (value: Record<string, number>) => void;
  getRoomMessagesFromLocalStorage: (roomId: string) => void;
  getRoomMessagesFromServer: (
    room: Room,
    params: PaginationLimitOffset,
  ) => void;
  handleCreateNewMessage: (
    data: {
      messageType: MessageType;
      messageContent?: string;
      messageMeta?: string;
      threadId?: string;
      roomId?: string;
      notificationInfo?: NotificationInfo;
      additionalData?: {
        messageId: string;
        content: string;
      };
      encryptType?: RoomEncryptType;
    },
    socketRef: any,
  ) => Promise<void>;
  dataSendingMessage: UserMessage[];
  setDataSendingMessage: React.Dispatch<React.SetStateAction<UserMessage[]>>;
  handleAddSendingMessage: (message: UserMessage) => void;
  handleRemoveSendingMessage: (idMessate: string) => void;
}>({
  messageListByRoom: {},
  setMessageListByRoom: () => {},
  selectedThread: undefined,
  setSelectedThread: () => {},
  loadingMessages: {},
  setLoadingMessages: () => {},
  totalMessagesByRoom: {},
  setTotalMessagesByRoom: () => {},
  getRoomMessagesFromLocalStorage: () => {},
  getRoomMessagesFromServer: () => {},
  handleCreateNewMessage: () => Promise.resolve(),
  dataSendingMessage: [],
  setDataSendingMessage: () => {},
  handleAddSendingMessage: () => {},
  handleRemoveSendingMessage: () => {},
});

export const MessageContextProvider = ({ children }: PropsWithChildren) => {
  const currentDate = new Date();
  const dispatch = useAppDispatch();

  const [messageListByRoom, setMessageListByRoom] = useState<
    Record<string, UserMessage[]>
  >({});
  const [totalMessagesByRoom, setTotalMessagesByRoom] = useState<
    Record<string, number>
  >({});
  const [selectedThread, setSelectedThread] = useState<UserMessage>();
  const [loadingMessages, setLoadingMessages] = useState({});
  const [dataSendingMessage, setDataSendingMessage] = useState<UserMessage[]>(
    [],
  );
  const { userData } = useAuthContext();

  const { selectedRoom, rooms } = useChatContext();
  // const { channel } = useChannelContext();
  // const { roomEncryptKeys } = useKeysStore();
  const roomsRef = useRef<Room[]>();
  const messageListByRoomRef = useRef<typeof messageListByRoom>();
  // const roomEncryptKeysRef = useRef<Record<string, string>>({});
  const loadingMessageRef = useRef<typeof loadingMessages>();

  const selectedRoomRef = useRef<Room>();
  const selectedThreadRef = useRef<typeof selectedThread>();

  useEffect(() => {
    if (selectedRoom?.id !== selectedRoomRef.current?.id) {
      setSelectedThread(undefined);
    }
  }, [selectedRoom]);

  messageListByRoomRef.current = messageListByRoom;
  roomsRef.current = rooms;
  loadingMessageRef.current = loadingMessages;
  selectedThreadRef.current = selectedThread;
  selectedRoomRef.current = selectedRoom;

  // const channelRef = useRef<typeof channel>({});

  // useEffect(() => {
  //   channelRef.current = channel;
  // }, [channel]);

  // useEffect(() => {
  //   roomEncryptKeysRef.current = roomEncryptKeys;
  // }, [roomEncryptKeys]);

  // const { checkIsRoomP2PConnected } = useRoomHelper();
  // const { handleJoinWebRTCRoom } = useRTCContext();

  // const { roomKeyExchangeStatus } = useKeyExchange();
  // const { canSendMessage } = useCreateMessageContext();

  const getRoomMessagesFromLocalStorage = useCallback((roomId: string) => {
    let messageListByRoom = [];

    try {
      messageListByRoom = JSON.parse(localStorage.getItem(roomId) ?? "[]");
      setMessageListByRoom({
        ...messageListByRoomRef.current,
        [roomId]: messageListByRoom,
      });
    } catch (error) {
      console.log("Cannot get room messageListByRoom");
    }
  }, []);

  const getRoomMessagesFromServer = useCallback(
    async (room: Room, params: PaginationLimitOffset) => {
      const { id: roomId } = room;
      try {
        setLoadingMessages({
          ...loadingMessageRef.current,
          [roomId]: true,
        });
        const messages = await handleGetRoomMessages({
          roomId,
          ...params,
        });
        await processMessageList(room, {
          ...messages,
          offset: params.offset,
        });
        setTotalMessagesByRoom({
          ...totalMessagesByRoom,
          [roomId]: messages.total,
        });
      } catch (error) {
        console.error("Error get room messages", error);
        toast.error("Error get room messages");
      } finally {
        setLoadingMessages({
          ...loadingMessageRef.current,
          [roomId]: false,
        });
      }
    },
    [],
  );

  const processMessageList = useCallback(
    async (room: Room, data: GetListRoomMessageResponse) => {
      const { id: roomId } = room;
      let messageContents: Record<
        string,
        { content: string; messageMeta: string }
      > = {};
      let messagesToGetContent = data.messages
        .filter((item) => !isMessageContentValid(item))
        .map((item) => item.messageId);
      messagesToGetContent = messagesToGetContent.concat(
        data.messages.reduce((pre, curr) => {
          return (pre ?? []).concat(
            (curr.threadMessages ?? [])
              ?.filter((item) => !isMessageContentValid(item))
              .map((item) => item.messageId) ?? [],
          );
        }, [] as string[]),
      );

      if (messagesToGetContent.length > 0) {
        try {
          const messages = await handleGetMessageContent(messagesToGetContent);
          messageContents = messages.data.reduce(
            (pre, curr) => ({
              ...pre,
              [curr.id]: {
                content: curr.content,
                messageMeta: curr.message_meta,
              },
            }),
            {},
          );
        } catch (error) {
          console.error("cannot get message content", error);
        }
      } else {
        messageContents = {};
      }

      let newMessages = data.messages.map((message) => {
        if (!messageContents[message.messageId]) {
          return message;
        }

        return {
          ...message,
          message: {
            ...message.message,
            content:
              messageContents[message.messageId].content ??
              message.message.content,
            messageMeta:
              messageContents[message.messageId].messageMeta ??
              message.message.messageMeta,
          },
          threadMessages:
            message.threadMessages?.map((thread) => {
              const currentMessage = messageContents[thread.messageId];
              if (!currentMessage) {
                return thread;
              }
              return {
                ...thread,
                message: {
                  ...message.additionalData,
                  ...thread.message,
                  content: currentMessage.content ?? thread.message.content,
                  messageMeta:
                    currentMessage.messageMeta ?? thread.message.messageMeta,
                },
              };
            }) ?? [],
        };
      });

      const messagesMap = newMessages.reduce(
        (pre, curr) => {
          return {
            ...pre,
            [curr.messageId]: curr,
            ...(curr.threadMessages.length > 0
              ? {
                  [curr.threadMessages[0].messageId]: curr.threadMessages[0],
                }
              : {}),
          };
        },
        {} as Record<string, UserMessage>,
      );

      if (room?.encryptType !== RoomEncryptTypeEnum.none) {
        const decryptedMessagesPromise = Object.keys(messagesMap).map(
          async (messageId) => {
            try {
              const message = messagesMap[messageId];
              return handleDecryptMessageContent(message.message, room);
            } catch (error) {
              console.error(error);
            }
          },
        );

        const decryptedMessages = await Promise.allSettled(
          decryptedMessagesPromise,
        );
        const decryptedMessagesMap = decryptedMessages.reduce(
          (pre, curr) => {
            if (curr.status === "fulfilled") {
              return {
                ...pre,
                [curr.value.id]: curr.value,
              };
            }
            return pre;
          },
          {} as Record<string, Message>,
        );

        newMessages = newMessages.map((item) => ({
          ...item,
          message: {
            ...item.message,
            ...(decryptedMessagesMap[item.messageId] ?? {}),
          },
          threadMessages: item.threadMessages.map((thread) => ({
            ...thread,
            message: {
              ...thread.message,
              ...(decryptedMessagesMap[thread.messageId] ?? {}),
            },
          })),
        }));
      }

      setMessageListByRoom({
        ...messageListByRoomRef.current,
        [roomId]: mergeArrays(
          "id",
          (messageListByRoomRef.current[roomId] ?? []).slice(0, data.offset),
          newMessages,
        ),
      });
    },
    [handleDecryptMessageContent],
  );

  const handleAddSendingMessage = useCallback((message: UserMessage) => {
    setDataSendingMessage((prevMessages) => [...prevMessages, message]);
  }, []);

  const handleRemoveSendingMessage = useCallback((messageId: string) => {
    setDataSendingMessage((prevMessages) => {
      const updatedMessages = prevMessages.filter(
        (item) => item?.additionalData?.messageId !== messageId,
      );
      return updatedMessages;
    });
  }, []);

  const getFilteredTagIds = useCallback(
    (tagLists: string[], users: UserRoom[]) => {
      const filterIdTagList = tagLists.flatMap((tag) => {
        const user = users.find((item) => tag === getUserRoomDisplayName(item));
        return user ? user.userId : [];
      });

      return filterIdTagList;
    },
    [],
  );

  const sendMessage = async (
    data: {
      content?: string;
      messageType: MessageType;
      threadId?: string;
      messageMeta?: any;
      roomId: string;
      notificationInfo: NotificationInfo;
      additionalData: {
        messageId: string;
        content: string;
      };
    },
    socketRef,
  ) => {
    const {
      content,
      messageMeta,
      messageType,
      threadId,
      roomId,
      notificationInfo,
      additionalData,
    } = data;
    if (socketRef && socketRef.current) {
      socketRef.current.emit(SocketEventTypeEnum.addMessage, {
        content,
        threadId,
        messageMeta,
        messageType,
        roomId,
        notificationInfo,
        additionalData,
      });
    }
  };

  const handleCreateNewMessage = useCallback(
    async (
      data: {
        messageType: MessageType;
        messageContent?: string;
        messageMeta?: string;
        threadId?: string;
        roomId?: string;
        notificationInfo: NotificationInfo;
        additionalData: {
          messageId: string;
          content: string;
        };
      },
      socketRef,
    ) => {
      const {
        messageType,
        messageContent,
        messageMeta,
        threadId,
        roomId,
        notificationInfo,
      } = data;
      try {
        if (!messageContent && !messageMeta) {
          toast.error("Message content is empty");
        }
        const messageId = uuidv4();
        const tagLists = findUsernameTagged(messageContent);

        const newIds: string[] = selectedRoom?.users
          ? getFilteredTagIds(tagLists, selectedRoom?.users)
          : [];

        let meta: MessageMeta | null;
        if (messageMeta) {
          meta = JSON.parse(messageMeta);
        } else {
          meta = null;
        }

        let subtype: AppMessageSubType;
        if (messageType === MESSAGE_TYPE.FILE && meta) {
          if (meta.file_type.includes("image")) {
            subtype = SUBTYPE.IMG;
          } else {
            subtype = SUBTYPE.DOC;
          }
        } else {
          subtype = SUBTYPE.TEXT;
        }

        const data = {
          messageType,
          threadId,
          roomId,
          content: undefined,
          messageMeta: undefined,
          notificationInfo,
          additionalData: {
            messageId,
            content: messageContent,
            tagList: newIds,
          },
        };

        const newMessage = {
          messageId: messageId,
          createdAt: currentDate.toISOString(),
          senderId: userData?.id,
          receiverId: null,
          roomId: roomId,
          threadId,
          message: {
            subtype,
            id: messageId,
            messageMeta: null,
            content: messageContent,
            meta,
            messageType,
            img: meta?.plain_url,
          },
          threadMessages: [],
          additionalData: {
            messageId,
            content: messageContent,
            isSending: true,
            tagList: newIds,
          },
        };
        switch (messageType) {
          case MessageTypeEnum.file:
            if (selectedRoom?.encryptType) {
              const roomEncryptKey = getOneLocalStorageRoomEncryptKey(roomId);
              try {
                data.messageMeta = await handleEncryptMessage({
                  message: messageMeta,
                  roomEncryptType: selectedRoom?.encryptType,
                  key: roomEncryptKey,
                });
              } catch (error) {
                console.log("Error encrypt message meta");
                return;
              }
            } else {
              data.messageMeta = messageMeta;
            }

            break;
          default:
            if (selectedRoom?.encryptType) {
              const roomEncryptKey = getOneLocalStorageRoomEncryptKey(roomId);

              try {
                data.content = await handleEncryptMessage({
                  message: messageContent,
                  roomEncryptType: selectedRoom?.encryptType,
                  key: roomEncryptKey,
                });
              } catch (error) {
                console.error(error);
                return;
              }
            } else {
              data.content = messageContent;
            }
            break;
        }

        handleAddSendingMessage(newMessage);
        sendMessage(data, socketRef);
      } catch (error) {
        toast.error(getErrorMessage(error));
      }
    },
    [selectedRoom],
  );

  return (
    <MessageContext.Provider
      value={{
        messageListByRoom,
        setMessageListByRoom,
        selectedThread,
        setSelectedThread,
        loadingMessages,
        setLoadingMessages,
        totalMessagesByRoom,
        setTotalMessagesByRoom,
        getRoomMessagesFromLocalStorage,
        getRoomMessagesFromServer,
        handleCreateNewMessage,
        dataSendingMessage,
        setDataSendingMessage,
        handleRemoveSendingMessage,
        handleAddSendingMessage,
      }}
    >
      {children}
    </MessageContext.Provider>
  );
};

export const useMessageContext = () => {
  return useContext(MessageContext);
};
