import { createSlice } from "@reduxjs/toolkit";
import { Image } from "../../services";

interface ImageInitialStateType {
  openImageViewer: boolean;
  selectedImage: Image;
  listImage: Image[];
}

const initialState: ImageInitialStateType = {
  openImageViewer: false,
  selectedImage: null,
  listImage: [],
};

const imageSlice = createSlice({
  name: "image",
  initialState,
  reducers: {
    setOpenImageViewer(state, action) {
      state.openImageViewer = action.payload;
    },
    setListImage(state, action) {
      state.listImage = action.payload;
    },
    setSelectedImage(state, action) {
      state.selectedImage = action.payload;
    },
  },
});

export default imageSlice.reducer;

export const { setListImage, setOpenImageViewer, setSelectedImage } =
  imageSlice.actions;
