import { UserMessage } from "../services";

export const isMessageContentValid = (message: UserMessage) => {
  return !!message.message.content || !!message.message.messageMeta;
};
export function parseJSONMessage<T>(message: string): T | null {
  try {
    return JSON.parse(message) as T;
  } catch (e) {
    return null;
  }
}
