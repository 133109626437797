export const getDisplayKeyFromPrefixString = (value?: string) => {
  if (!value) return "";
  return value.split("|").pop() ?? "";
};
export const getPreviousPrefix = (value?: string) => {
  if (!value) return "";
  return value.split("|").slice(0, -1).join("|") ?? "";
};
export const getLocalStorageKey = (prefix: string, key: string) => {
  return `${prefix}|${key}`;
};
