import { createSlice } from "@reduxjs/toolkit";

interface ChatInitialStateType {
  chatUnreadCount: object;
  searchValue: string;
  loadingRoom: boolean;
  selectedRoomLoadingId: string;
}

// define initial state
const initialState: ChatInitialStateType = {
  chatUnreadCount: {},
  searchValue: "",
  loadingRoom: false,
  selectedRoomLoadingId: "",
};

// create chatSlice
const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    //Toggle sidebar
    setChatUnreadCount(state, action) {
      state.chatUnreadCount[action.payload.roomId] = action.payload.count;
    },
    setAllChatUnreadCount(state, action) {
      state.chatUnreadCount = action.payload;
    },
    setSearchValue(state, action) {
      state.searchValue = action.payload;
    },
    setLoadingRoom(state, action) {
      state.loadingRoom = action.payload;
    },
    setSelectedRoomLoadingId(state, action) {
      state.selectedRoomLoadingId = action.payload;
    },
  },
});

// export reducer
export default chatSlice.reducer;

// export actions
export const {
  setChatUnreadCount,
  setAllChatUnreadCount,
  setSearchValue,
  setLoadingRoom,
  setSelectedRoomLoadingId,
} = chatSlice.actions;
